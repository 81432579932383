import AppBar, { type AppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import { CONFIG } from '@constants';

interface IHeaderStyled extends AppBarProps {
  open?: boolean;
}

export const HeaderStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IHeaderStyled>(({ open, theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7.5)})`,
  }),
  ...(open && {
    marginLeft: CONFIG.SIDEBAR_WIDTH,
    width: `calc(100% - ${CONFIG.SIDEBAR_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
