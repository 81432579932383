import { Box, Typography } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { menuItems } from '@menu';

import { type IMenuItem } from '@features/menu/menuSlice';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import NavGroup from '@layout/MainLayout/Sidebar/SidebarContent/Navigation/NavGroup';
import NavItem from '@layout/MainLayout/Sidebar/SidebarContent/Navigation/NavItem';

const Navigation = () => {
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menu, setMenu] = useState<IMenuItem[]>([]);

  const { navigationStyles } = useModuleStyles();

  useEffect(() => {
    const menu = uniqBy(menuItems.items, 'id');
    setMenu(menu);
  }, []);

  const navGroups = menu.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            groupTitle={item.title ?? ''}
            item={item}
            key={item.id}
            selectedGroup={selectedGroup}
            selectedLevel={selectedLevel}
            setSelectedGroup={setSelectedGroup}
            setSelectedLevel={setSelectedLevel}
          />
        );
      case 'item':
        return <NavItem item={item} key={item.id} level={1} />;
      default:
        return (
          <Typography align="center" color="error" key={item.id} variant="h6">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={navigationStyles}>{navGroups}</Box>;
};

export default Navigation;
