import { List, Typography } from '@mui/material';
import { type FC } from 'react';

import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import type { INavGroup } from '@layout/MainLayout/Sidebar';
import NavCollapse from '@layout/MainLayout/Sidebar/SidebarContent/Navigation/NavCollapse';
import NavItem from '@layout/MainLayout/Sidebar/SidebarContent/Navigation/NavItem';

const NavGroup: FC<INavGroup> = ({
  item,
  groupTitle,
  selectedLevel,
  selectedGroup,
  setSelectedLevel,
  setSelectedGroup,
}) => {
  const { navGroupListStyles } = useModuleStyles();

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse': {
        return (
          <NavCollapse
            groupTitle={groupTitle}
            key={menuItem.id}
            level={1}
            menu={menuItem}
            parentId={''}
            selectedGroup={selectedGroup}
            selectedLevel={selectedLevel}
            setSelectedGroup={setSelectedGroup}
            setSelectedLevel={setSelectedLevel}
          />
        );
      }

      case 'item':
        return <NavItem item={menuItem} key={menuItem.id} level={1} />;
      default:
        return (
          <Typography
            align="center"
            color="error"
            key={menuItem.id}
            variant="h6"
          >
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return <List sx={navGroupListStyles}>{navCollapse}</List>;
};

export default NavGroup;
