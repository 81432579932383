import Drawer, { type DrawerProps } from '@mui/material/Drawer';
import { type CSSObject, styled, type Theme } from '@mui/material/styles';

import { CONFIG } from '@constants';

const openedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  width: CONFIG.SIDEBAR_WIDTH,
  borderRight: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.palette.mode === 'dark' ? theme.customShadows.z1 : 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  borderRight: 'none',
  overflowX: 'hidden',
  width: theme.spacing(7.5),
  boxShadow: theme.customShadows.z1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

interface IDrawerProps extends DrawerProps {
  open?: boolean;
}

export const MiniSideBarStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IDrawerProps>(({ open, theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: CONFIG.SIDEBAR_WIDTH,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
