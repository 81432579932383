import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

import { Clock } from '@components';

import { setAppConfig } from '@features/appConfig/appConfigSlice';
import { useAppDispatch, useAppSelector, useBreakPoints } from '@hooks';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';

const HeaderContent = () => {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector((state) => state.appConfig.config);
  const isDarkMode = appConfig.mode === 'dark';

  const { isTabletScreen } = useBreakPoints();

  const handleModeChange = useCallback(
    () =>
      dispatch(
        setAppConfig({ mode: appConfig.mode === 'light' ? 'dark' : 'light' })
      ),
    [dispatch, appConfig.mode]
  );

  const { themeButtonStyles, mobileHeaderContainerStyles } = useModuleStyles();

  return (
    <>
      {isTabletScreen && <Box sx={{ marginLeft: 1, width: '100%' }} />}
      {!isTabletScreen && (
        <Stack sx={mobileHeaderContainerStyles}>
          <Clock />
          <Tooltip color="secondary" title="Theme mode">
            <IconButton
              disableRipple
              color="secondary"
              edge="start"
              onClick={handleModeChange}
              sx={themeButtonStyles}
            >
              {isDarkMode ? (
                <MdLightMode fontSize="large" />
              ) : (
                <MdDarkMode fontSize="large" />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};

export default HeaderContent;
