import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  type Dispatch,
  type FC,
  type MouseEvent,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuTransition } from '@components';

import {
  activeItem,
  type IMenuItem,
  OverrideIcon,
} from '@features/menu/menuSlice';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import NavItem from '@layout/MainLayout/Sidebar/SidebarContent/Navigation/NavItem';
import { getNavGroupWithActiveItem } from '@layout/MainLayout/utils/getNavGroupWithActiveItem';

const PopperStyled = styled(Popper)(({ theme }) => ({
  zIndex: 1202,
  minWidth: 180,
  overflow: 'visible',
  '&:before': {
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    zIndex: 120,
    content: '""',
    display: 'block',
    position: 'absolute',
    transform: 'translateY(-50%) rotate(45deg)',
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.grey.A800 ?? ''}`,
    borderBottom: `1px solid ${theme.palette.grey.A800 ?? ''}`,
  },
}));

interface INavCollapse {
  level: number;
  menu: IMenuItem;
  parentId: string;
  groupTitle: string;
  selectedLevel: number;
  selectedGroup: string | undefined;
  setSelectedLevel: Dispatch<SetStateAction<number>>;
  setSelectedGroup: Dispatch<SetStateAction<string>>;
}

const NavCollapse: FC<INavCollapse> = ({
  menu,
  level,
  parentId,
  groupTitle,
  selectedLevel,
  selectedGroup,
  setSelectedLevel,
  setSelectedGroup,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openItem, isSidebarOpen } = useAppSelector((state) => state.menu);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<null | string | undefined>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const groupWithActiveItem = useMemo(
    () => getNavGroupWithActiveItem(pathname),
    [pathname]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    setSelectedLevel(level);

    if (isSidebarOpen) {
      setOpen(!open);
      setSelected(!selected && menu.id === openItem?.[0] ? menu.id : '');
      setSelectedGroup(!selected ? (menu.id as string) : '');

      if (menu.url) {
        navigate(menu.url);
      }
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleListItemIconClick = () => {
    if (!isSidebarOpen) {
      if (menu.url) {
        navigate(menu.url);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
    setAnchorEl(null);
  };

  useMemo(() => {
    if (groupWithActiveItem === menu.id) {
      setOpen(true);
    }
    if (level === selectedLevel) {
      setSelected(null);
    }
  }, [groupWithActiveItem, level, menu.id, selectedLevel]);

  const openMini = Boolean(anchorEl);

  useEffect(() => {
    if (menu.url === pathname) {
      dispatch(activeItem({ openItem: [menu?.id ?? ''] }));
      setSelected(menu.id);
      setAnchorEl(null);
      setOpen(true);
    }
  }, [pathname, menu, dispatch]);

  const handleHideMenu = useCallback(() => {
    setOpen(false);
    setSelected(null);
  }, []);

  const {
    listItemStyles,
    listItemTextColor,
    listItemIconStyles,
    navCollapsePaperStyles,
    navCollapseArrowUpStyles,
    navCollapseArrowDownStyles,
  } = useModuleStyles({
    isSidebarOpen,
    menuItem: menu,
    groupWithActiveItem,
  });

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return (
          <NavCollapse
            groupTitle={groupTitle}
            key={item.id}
            level={level + 1}
            menu={item}
            parentId={parentId}
            selectedGroup={selectedGroup}
            selectedLevel={selectedLevel}
            setSelectedGroup={setSelectedGroup}
            setSelectedLevel={setSelectedLevel}
          />
        );
      case 'item':
        return (
          <NavItem
            hideMenu={handleHideMenu}
            item={item}
            key={item.id}
            level={level + 1}
          />
        );
      default:
        return (
          <Typography align="center" color="error" key={item.id} variant="h6">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const Icon = menu.icon as OverrideIcon;

  const menuIcon = menu.icon ? (
    <Icon style={{ fontSize: isSidebarOpen ? '1rem' : '1.25rem' }} />
  ) : null;

  return (
    <>
      <ListItemButton
        disableRipple
        selected={selected === menu.id}
        {...(!isSidebarOpen
          ? {
              onMouseEnter: handleClick,
              onMouseLeave: handleClose,
            }
          : {})}
        onClick={handleClick}
        sx={listItemStyles}
      >
        {menuIcon ? (
          <ListItemIcon
            onClick={handleListItemIconClick}
            sx={listItemIconStyles}
          >
            {menuIcon as ReactNode}
          </ListItemIcon>
        ) : null}
        {(isSidebarOpen ?? (!isSidebarOpen && level !== 1)) ? (
          <ListItemText
            primary={
              <Typography
                color={
                  selected === menu.id ? 'primary.main' : listItemTextColor
                }
                variant="h6"
              >
                {groupTitle}
              </Typography>
            }
          />
        ) : null}
        {(isSidebarOpen ?? (!isSidebarOpen && level !== 1)) ? (
          openMini || open ? (
            <IoIosArrowUp style={navCollapseArrowUpStyles} />
          ) : (
            <IoIosArrowDown style={navCollapseArrowDownStyles} />
          )
        ) : null}

        {!isSidebarOpen && menu.children?.length ? (
          <PopperStyled
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1],
                  },
                },
              ],
            }}
            style={{
              zIndex: 2001,
            }}
            anchorEl={anchorEl}
            open={openMini}
            placement="right-start"
          >
            {({ TransitionProps }) => (
              <MenuTransition in={openMini} {...TransitionProps}>
                <Paper sx={navCollapsePaperStyles}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </MenuTransition>
            )}
          </PopperStyled>
        ) : null}
      </ListItemButton>
      {isSidebarOpen ? (
        <Collapse in={open} timeout="auto">
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      ) : null}
    </>
  );
};

export default NavCollapse;
