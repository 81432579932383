import {
  AppBar,
  type AppBarProps,
  Box,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import { type FC, type ReactNode, useMemo } from 'react';
import { RiMenuFoldFill, RiMenuUnfoldFill } from 'react-icons/ri';

import { Breadcrumbs } from '@components';

import { useBreakPoints } from '@hooks';
import { HeaderStyled } from '@layout/MainLayout/Header';
import { type IHeader } from '@layout/MainLayout/Header/Header.types';
import HeaderContent from '@layout/MainLayout/Header/HeaderContent';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';

export const Header: FC<IHeader> = ({
  isIframe,
  isSidebarOpen,
  handleSidebarToggle,
}) => {
  const { isTabletScreen, isDesktopScreen } = useBreakPoints();
  const { appBarStyles, sidebarSwitcherStyles, headerLeftColumnStyles } =
    useModuleStyles({
      isSidebarOpen,
    });

  const headerContent = useMemo(() => <HeaderContent />, []);

  const mainHeader: ReactNode = (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <Stack sx={headerLeftColumnStyles}>
        {!isIframe ? (
          <IconButton
            disableRipple
            aria-label="open drawer"
            color="secondary"
            edge="start"
            onClick={handleSidebarToggle}
            sx={sidebarSwitcherStyles}
          >
            {!isSidebarOpen ? <RiMenuUnfoldFill /> : <RiMenuFoldFill />}
          </IconButton>
        ) : null}
        {isTabletScreen ? (
          <Box sx={{ marginLeft: 1, width: '100%' }} />
        ) : (
          <Breadcrumbs />
        )}
      </Stack>
      {headerContent}
    </Toolbar>
  );

  const appBar: AppBarProps = {
    elevation: 0,
    color: 'inherit',
    sx: appBarStyles,
    position: 'fixed',
  };

  return isDesktopScreen ? (
    <AppBar {...appBar}>{mainHeader}</AppBar>
  ) : (
    <HeaderStyled open={isSidebarOpen} {...appBar}>
      {mainHeader}
    </HeaderStyled>
  );
};
