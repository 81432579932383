import { Box, CardMedia, Stack, Typography } from '@mui/material';
import { type FC } from 'react';

import Logo from '@assets/images/logo.svg';

import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';

interface ISidebarHeader {
  open: boolean;
}

const SidebarHeader: FC<ISidebarHeader> = ({ open }) => {
  const {
    sidebarHeaderStyles,
    sidebarHeaderLogoStyles,
    sidebarHeaderContainerStyles,
  } = useModuleStyles({ isSidebarOpen: open });

  return (
    <Box sx={sidebarHeaderStyles}>
      <Stack sx={sidebarHeaderContainerStyles}>
        <CardMedia
          alt="Bonus Logo"
          component="img"
          src={Logo}
          sx={sidebarHeaderLogoStyles}
        />
        {open ? (
          <Typography sx={{ paddingLeft: '1rem' }} variant="h5">
            Bonuses System
          </Typography>
        ) : null}
      </Stack>
    </Box>
  );
};

export default SidebarHeader;
