export const groupItemPath: Record<string, string[]> = {
  'manual-assignments': ['/give-bonus', '/activate-code'],
  campaigns: [
    '/automatic-campaigns',
    '/scheduled-campaigns',
    '/code-campaigns',
  ],
  reports: [
    '/player-bonuses',
    '/player-offers',
    '/player-codes',
    '/monitor-report',
    '/activity-report',
  ],
};
