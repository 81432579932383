import { ListItemButton, ListItemIcon, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  type FC,
  forwardRef,
  type ForwardRefExoticComponent,
  type Ref,
  type RefAttributes,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import { activeItem, type OverrideIcon } from '@features/menu/menuSlice';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useModuleStyles } from '@layout/MainLayout/hooks/useModuleStyles';
import type { INavItem } from '@layout/MainLayout/Sidebar';
import { setCampaignConfig } from '@modules/automaticCampaigns/store/automaticCampaignsSlice';

type TargetType = '_top' | '_self' | '_blank' | '_parent';

interface IListItemProps {
  to?: string;
  href?: string;
  target?: TargetType;
  component?:
    | string
    | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>;
}

const NavItem: FC<INavItem> = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { openItem, isSidebarOpen } = useAppSelector((state) => state.menu);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: IListItemProps = {
    component: forwardRef((props, ref: Ref<HTMLAnchorElement>) => (
      <Link ref={ref} {...props} target={itemTarget} to={item.url ?? ''} />
    )),
  };
  if (item?.external) {
    listItemProps = { to: item.url, target: itemTarget as TargetType };
  }

  const Icon = item.icon as OverrideIcon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: isSidebarOpen ? '1rem' : '1.25rem' }} />
  ) : null;

  const isSelected =
    openItem && openItem.findIndex((id) => id === item.id) > -1;

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';

  // TODO: Refactor after Orbit impl in BS-4097
  const onMenuItemClick = useCallback(
    (id: string) => {
      const locationPath = location.pathname.split('/')[1];
      if (locationPath !== item.id) {
        dispatch(setCampaignConfig({ module: '', actionType: '' }));
      }
      dispatch(activeItem({ openItem: [id] }));
    },
    [dispatch, location, item.id]
  );

  const { listItemIconStyles, listItemButtonStyles } = useModuleStyles({
    isSidebarOpen,
  });

  return (
    <ListItemButton
      {...listItemProps}
      onClick={() => {
        onMenuItemClick(item.id ?? '');
      }}
      sx={{
        paddingLeft: isSidebarOpen ? `${level === 1 ? 1.875 : 3}rem` : 1.5,
        ...listItemButtonStyles,
      }}
      disabled={item.disabled}
      selected={isSelected}
    >
      {!isSidebarOpen ? (
        <ListItemIcon sx={listItemIconStyles}>{itemIcon}</ListItemIcon>
      ) : null}
      {(isSidebarOpen ?? (!isSidebarOpen && level !== 1)) ? (
        <Stack sx={{ alignItems: 'center', flexDirection: 'row' }}>
          {itemIcon}
          <Typography
            noWrap
            sx={{
              p: '0.25rem 0.75rem',
              color: isSelected ? 'primary.main' : textColor,
            }}
            variant="h6"
          >
            {item.title}
          </Typography>
        </Stack>
      ) : level === 1 ? null : (
        <Typography
          sx={{ color: isSelected ? 'primary.main' : textColor }}
          variant="h6"
        >
          {item.title}
        </Typography>
      )}
    </ListItemButton>
  );
};

export default NavItem;
