import { groupItemPath } from '@layout/MainLayout/constants';

export const getNavGroupWithActiveItem = (pathname: string) => {
  for (const key in groupItemPath) {
    if (groupItemPath[key].includes(pathname)) {
      return key;
    }
  }
  return undefined;
};
